.record-audio-modal {
  background-color: white;

  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.015em;
    color: #11141B;
  }

  .heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #11141B;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 63px;
  }

  .li-input {
    width: 335px;

    .li-input__native {
      height: 40px;
    }
  }

  .buttons-block {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;

    button {
      height: 40px;
      width: 118px;
    }
  }
}