.custom-form {
  max-width: 100%;
  min-height: 2rem;
  background-color: white;
  box-shadow: 0 2px 12px rgba(79, 93, 102, 0.06);
  border-radius: 4px;
  padding: 40px 48px;

  > div {
    padding-bottom: 24px;
  }

  @media only screen and (max-width: 700px) {
   padding: 40px 15px;
  }
}