.custom-recorder {
  .audio-react-recorder {
    display: none;
  }

  .microphone-button {
    border-radius: 50%;
    cursor: pointer;
    background: #F0F5F5;
    height: 87px;
    width: 87px;
  }

  .recording-button {
    border-radius: 50%;
    cursor: pointer;
    background: #1E62E6;
    height: 87px;
    width: 87px;
    margin-right: 20px;
  }

  .stop-button {
    border-radius: 50%;
    cursor: pointer;
    background: #F0F5F5;
    height: 40px;
    width: 40px;
  }
}