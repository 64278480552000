.li-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  height: 100vh;
  width: 100%;
  overflow: auto;

  &__overlay {
    position: relative;
    max-width: 1050px;
    width: 50%;
    padding: 3.5rem;
    min-height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    background-color: white;

    .children {
      max-height: 65vh;
      //overflow: auto;
      width: 100%;
    }
    .close {
      position: absolute;
      color: red;
      right: 0.5rem;
      top: 0.5rem;
      font-size: 3rem;
      cursor: pointer;
    }

    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }

  &__header {
    font-size: 2.5rem;
    text-align: center;
  }

  &__body {
    margin-top: 2.5rem;
  }
}
