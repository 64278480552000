html, body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
}

.app {
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > div {
    flex: 1
  }
}