.reset-password-page {
  background: #1E62E6;

  &__auth-block {
    display: flex;
    justify-content: center;

    .fields-block {
      &__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.015em;
        color: #11141B;
        text-align: center;
      }
    }
  }
}