@import "subpages/new-topic-subpage/new-topic-subpage.styles";
@import "subpages/unit-subpage/unit-subpage.styles";
@import "subpages/add-edit-unit-subpage/add-edit-unit.styles";

.topics-page {
  padding-top: 70px !important;

  &__heading-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    .li-input {
      input {
        border: 0;
        border-bottom: 2px solid #CFD8DF;
        background-color: transparent;
        border-radius: 0;
      }
    }

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;
      letter-spacing: 0.015em;
      color: #11141B;
    }
  }

  .settings-panel {
    display: flex;
    justify-content: flex-end;

    &__plus-icon {
      width: 150px;
      height: 40px;

      img {
        padding-right: 0.1rem;
      }
    }
  }
}