.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 0 0 0 2rem;
  height: 5rem;
  //height: 15%;

  &__main-link {
    a {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0.015em;
      color: #1E62E6;
      text-decoration: none;
    }
  }

  &__links-block {
    width: auto;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    display: flex;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8rem;
      color: #45484D;
      height: 100%;
      font-weight: 500;
      text-decoration: none;
      font-family: Montserrat;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
    }

    &_active {
      border-top: 3px solid #1E62E6;
      width: 4rem !important;
    }
  }

  &__profile-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 2rem;

      img {
        cursor: pointer;
      }
    }

    > div:nth-child(2) {
      border-right: 1px solid #E1E6EB;
    }
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: 7rem;
  }
}