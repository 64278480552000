.custom-tab {
  .nav-tabs {
    border-bottom: none;

    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #11141B;
    }

    .nav-link {
      background-color: transparent;
      border: none;
      padding: 1rem 2rem;
    }

    .active {
      border-bottom: 2px solid #11141B !important;
    }
  }

  .tab-content {
    margin-top: 34px;
  }
}